<template>
  <div class="dashboard-container" v-loading="loading">
    <el-row :gutter="20" style="margin-bottom: 0">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="clearfix echart-title">
            <en-year-month-picker :isMonthDefault="true" :optional="false" @changed="handleYearMonthChanged"/>
            <el-cascader
              style="width:19%;"
              expand-trigger="hover"
              :options="typeList"
              @change="activityTypeChange"
              :clearable="true"
              size="mini"
            ></el-cascader>
          </div>
          <div class="echart-alltitle">
            <div id="echarts_right_order" class="echarts-right" style="width:100%;"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 0">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="clearfix echart-title">
            <en-year-month-picker :isMonthDefault="true" :optional="false" @changed="handleYearMonthChangedShop"/>
          </div>
          <div class="echart-alltitle">
            <div id="echarts_right_shop" class="echarts-right" style="width:100%;"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as API_Home from "@/api/home";
import * as API_Sta from "@/api/$sta.trade.js";
import * as API_activityType from "@/api/activityType";
export default {
  name: "dashboard",
  data() {
    return {
      loading: true,
      // 活动分类
      typeList: [],
      category_first: '',
      category_second: '',
      category_id: '',
      priceMilli0: '',
      priceMilli1: '',

      params: {}
    };
  },
  created() {
    this.GET_activeTypeGet();
  },
  activated() {},
  mounted() {
    this.$nextTick(() => {
      this.sesalChartOrder = this.$echarts.init(
        document.getElementById("echarts_right_order")
      );
      this.sesalChartShop = this.$echarts.init(
        document.getElementById("echarts_right_shop")
      );
    });
  },
  methods: {
    /** 年月份发生变化 */
    handleYearMonthChanged(object) {
      const start = +new Date(object.year, object.month - 1, 1, 0, 0, 0) / 1000
      const end = +new Date(object.year, object.month, 0, 23, 59, 59) / 1000
      this.params = { start, end }
      this.Get_Order(start, end)
    },
    /** 年月份发生变化 */
    handleYearMonthChangedShop(object) {
      const start = +new Date(object.year, object.month - 1, 1, 0, 0, 0) / 1000
      const end = +new Date(object.year, object.month, 0, 23, 59, 59) / 1000
      this.Get_Shop(start, end)
    },
    /**  选择活动分类 */
    activityTypeChange(val) {
      this.category_first = null;
      this.category_second = null;
      this.categoryid = ''
      this.typeList.forEach((item1) => {
        if (item1.value === val[0]) {
          this.category_first = item1.value;
          if (item1.children && item1.children.length > 0) {
            item1.children.forEach((item2) => {
              if (item2.value === val[1]) {
                this.category_second = item2.value;
              }
            });
          }
        }
      });
      const { start, end } = this.params
      this.Get_Order(start, end);
    },
    //  查询活动分类列表
    GET_activeTypeGet() {
      API_activityType.activeTypeGet(0, {}).then((res) => {
        this.typeList = [];
        res.forEach((item1, index1) => {
          var jsonParent = {};
          jsonParent.value = item1.category_id;
          jsonParent.label = item1.name;
          var children = [];
          if (item1.children && item1.children.length > 0) {
            item1.children.forEach((item2, index2) => {
              var jsonChild = {};
              jsonChild.value = item2.category_id;
              jsonChild.label = item2.name;
              children.push(jsonChild);
            });
            jsonParent.children = children;
          }
          this.typeList.push(jsonParent);
        });
      });
    },
    handleActChange() {
      this.Get_trade_act();
    },
    Get_Order(start, end) {
      if (this.category_first) {
        this.category_id = this.category_first
      }
      if (this.category_second) {
        this.category_id = this.category_second
      }
      API_Sta.getPrice({
        start,
        end,
        categoryid: this.category_id || 0
      }).then((res) => {
        this.loading = false;
        const x = res.xAxis;
        const s = res.series;
        const map = new Map()
        s.data.forEach((e, i) => {
          map.set(e, s.localName[i])
        })
        const option = {
          title: {
            text: '活动客单价排行top20',
            left: 'center'
          },
          xAxis: {
            type: 'category',
            data: s.data
          },
          yAxis: {
            type: 'value',
            name: '客单价金额/元'
          },
          tooltip: {
            trigger: "axis",
            formatter(params) {
              const [p0] = params
              const label = map.get(p0.axisValue)
              const color = p0.color
              const val = p0.value
              // console.log(params, 'params');
              return `<div style="width:100%;height:100;">
                        <div style="display:flex;justify-content:center;align-items:center;padding-top: 8px;">${label}</div>
                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${color};margin-right:5px;"></div>
                              <div>客单价金额：</div>
                            </div>
                            <div class="item-value">
                              ${val}
                            </div>
                          </div>
                        </div>
                      </div>`
            },
          },
          series: [{
            data: x,
            type: 'bar'
          }]
        };
        this.sesalChartOrder.setOption(option);
      });
    },
    Get_Shop(start, end) {
      API_Sta.getRefund({
        start,
        end,
      }).then((res) => {
        const that = this;
        this.loading = false;
        const x = res.xAxis;
        const s = res.series;
        const map = new Map()
        s.data.forEach((e, i) => {
          map.set(e, s.localName[i])
        })
        const option = {
          title: {
            text: '退款笔数top20',
            left: 'center'
          },
          xAxis: {
            type: 'category',
            data: s.data
          },
          yAxis: {
            type: 'value',
            name: '退款笔数'
          },
          tooltip: {
            trigger: "axis",
            formatter(params) {
              const [p0] = params
              const label = map.get(p0.axisValue)
              const color = p0.color
              const val = p0.value
              // console.log(params, 'params');
              return `<div style="width:100%;height:100;">
                        <div style="display:flex;justify-content:center;align-items:center;padding-top: 8px;">${label}</div>
                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${color};margin-right:5px;"></div>
                              <div>退款笔数：</div>
                            </div>
                            <div class="item-value">
                              ${val}
                            </div>
                          </div>
                        </div>
                      </div>`
            },
          },
          series: [{
            data: x,
            type: 'bar'
          }]
        };
        this.sesalChartShop.setOption(option);
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.item-row {
  margin-bottom: 10px;
  .el-col {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
.item_list {
  background: #ffffff;
  border-radius: 12px;
  margin: 0 5px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    margin-top: 22px;
  }
  div {
    span {
      display: block;
    }
    span:nth-child(1) {
      color: #212121;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #434343;
    }
  }
  img {
    width: 82px;
    height: 82px;
  }
}
.echart-title::after {
  display: none;
}
.echart-title {
  height: 50px;
  font-size: 18px;
  color: #007f7f;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:nth-child(2) {
    color: #212121;
    font-size: 14px;
  }
  p span {
    cursor: pointer;
    line-height: 20px;
    height: 24px;
    display: inline-table;
    margin: 0 5px;
  }

  p span.active {
    color: #007f7f;
    border-bottom: 2px solid #007f7f;
  }
}

.echart-alltitle {
  display: flex;
  justify-content: space-around;
}
.echarts-left1 {
  width: 15%;
}
.echarts-left50 {
  width: 50%;
  padding: 10px;
  ::v-deep .layout-container{
    height: auto;
    background-color: #fff;
  }
}
.echarts-left {
  height: 100px;
  width: 100%;
  background: rgba(51, 205, 189, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 0px;
    color: #212121;
  }
  p {
    font-size: 15px;
    line-height: 0px;
    font-weight: 500;
    margin-top: -10px;
    color: #656565;
  }
}
.echarts-left:nth-child(2),
.echarts-left:nth-child(3) {
  background: rgba(253, 85, 87, 0.08);
}
.echarts-right {
  width: 80%;
  height: 350px;
}

.elcard {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background: #f3f5f7;
    border-radius: 8px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    padding: 0 5px;
    cursor: pointer;
  }
}

.dashboard-container {
  height: 100%;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .goods-statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    a {
      display: block;
      margin: 0;
      width: 75%;
      button {
        width: 100%;
      }
    }
  }
  .goods-image {
    width: 50px;
    height: 50px;
  }
}
.el-row {
  position: relative;
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
</style>
